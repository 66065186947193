
import { useHistory } from "react-router-dom";
import { 
    // useGetAppsQuery, 
    useGetAllServerAppContentVersionQuery,
    useAdminAddContentVersionDataMutation,
    useAdminDeleteContentVersionDataMutation,
} from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const context = {}

type contentVersionType = {
  serverVersionId: number
  appId: number
  contentVersionId: number
  versionNumber: string
  appName: string
  description: string
  appGroupId: number
  open: boolean
  edited: boolean
}

type contentVersionObj = {
  appId: number
  appName: string
  data: contentVersionType[]
}

const useGameContentVersionsHook = () => {
  const [deleteContentVersion] = useAdminDeleteContentVersionDataMutation()
  const [addContentVersion] = useAdminAddContentVersionDataMutation()

  const [processing, setProcessing] = React.useState(false)
    // const { data: dataApps } = useGetAppsQuery({
    //     context
    //   });
    const { data, loading } = useGetAllServerAppContentVersionQuery({
        context,
        fetchPolicy: 'no-cache',
      });
    const [contentVersions, setContentVersions] = React.useState([] as contentVersionObj[]);
    React.useMemo(() => {
      if (data && data.getAllServerAppContentVersion?.data?.length) {
        const allData = data.getAllServerAppContentVersion?.data
        const dataCT = [] as contentVersionObj[]
        for (let i = 0; i < allData?.length; i++) {
          const elData = allData[i]
          const fIdx = dataCT.findIndex((xItem: contentVersionObj) => xItem.appId == elData.appId)
          if (fIdx == -1) {
            dataCT.push({
              appId: elData.appId,
              appName:  elData.appName,
              data: [elData]
            } as contentVersionObj)
          } else {
            dataCT[fIdx].data.push(elData)
          }
        }
        setContentVersions(dataCT)
      }
    }, [data]);

    

    const columns = [
      {
          label: "ID",
          id: "ID",
          width: "50px"
      },
      {
          label: "Name",
          id: "Name",
          width: "200px"
      },
      {
          label: "GameMode",
          id: "GameMode",
      },
      {
          label: "NumberPlayer",
          id: "NumberPlayer",
          width: "100px"
      },
      {
          label: "HasHighscore",
          id: "HasHighscore",
          width: "100px"
      }
    ]

  const addNewContentVersions = (el: contentVersionObj) => {
    const cloneCV = [...contentVersions]
    const fIdx = cloneCV.findIndex((xItem: contentVersionObj) => xItem.appId == el.appId)
    if (fIdx != -1) {
      const newContentVersion: contentVersionType = {
        serverVersionId: el.data[0].serverVersionId,
        appId: el.appId,
        contentVersionId: 0,
        versionNumber: el.data[0].versionNumber,
        appName: el.appName,
        description: "",
        appGroupId: el.data[0].appGroupId,
        open: el.data[0].open,
        edited: true,
      }
      cloneCV[fIdx].data.push(newContentVersion)
      setContentVersions(cloneCV)
    }
  }

  const onSaveContent = async (cti: contentVersionType) => {
    if (!cti.description || !cti.versionNumber) {
      return
    }

    setProcessing(true)
    try {
      const {data, errors} = await addContentVersion({
        variables: {
          input: {
            versionNumber: cti.versionNumber,
            description: cti.description,
            status: "PUBLISHED",
            appId: cti.appId,
          }
        }
      })

      const cloneCV = [...contentVersions]
      console.log('onSaveContent cloneCV 1', cloneCV)
      const fIdx = cloneCV.findIndex((xItem: contentVersionObj) => xItem.appId == cti.appId)
      if (fIdx != -1) {
        const dataIdx = cloneCV[fIdx]
        const dIdx = dataIdx.data.findIndex((el: any) => el.contentVersionId == cti.contentVersionId)
        cloneCV[fIdx].data[dIdx].contentVersionId = data?.adminAddContentVersionData?.contentVersionId || 0
        cloneCV[fIdx].data[dIdx].edited = false
        setContentVersions(cloneCV)
      }

      toast.success("Success !!!");
    } catch (e) {
      toast.error("ERROR !!!");
    }
    setProcessing(false)
  }

  const onDeleteContent = async (item: contentVersionType) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    if (!result.isConfirmed) {
      return;
    }
    if (!item.contentVersionId) {
      return
    }
    setProcessing(true)
    const {data, errors} = await deleteContentVersion({
      variables: {
        input: {
          id: item.contentVersionId
        }
      }
    })
    if (errors && errors?.length) {
      toast.error("ERROR !!!");
    } else {
      toast.success("Success !!!");
      const cloneCV = [...contentVersions]
      const fIdx = cloneCV.findIndex((xItem: contentVersionObj) => xItem.appId == item.appId)
      if (fIdx != -1) {
        const dataIdx = cloneCV[fIdx]
        cloneCV[fIdx].data = dataIdx.data.filter((el: any) => el.contentVersionId != item.contentVersionId)
        setContentVersions(cloneCV)
      }
    }
    setProcessing(false)
  }

  const onChangeDes = (ev: any, item: contentVersionType) => {
    const { value } = ev.target;

    const cloneCV = [...contentVersions]
    const fIdx = cloneCV.findIndex((xItem: contentVersionObj) => xItem.appId == item.appId)
    if (fIdx != -1) {
      const iIdx = cloneCV[fIdx].data.findIndex((xItem: contentVersionType) => xItem.appId == item.appId && xItem.contentVersionId == item.contentVersionId)
      if (fIdx != -1) {
        cloneCV[fIdx].data[iIdx].description = value
      }
      setContentVersions(cloneCV)
    }
  }

  const onChangeVersion = (ev: any, item: contentVersionType) => {
    const { value } = ev.target;

    const cloneCV = [...contentVersions]
    const fIdx = cloneCV.findIndex((xItem: contentVersionObj) => xItem.appId == item.appId)
    if (fIdx != -1) {
      const iIdx = cloneCV[fIdx].data.findIndex((xItem: contentVersionType) => xItem.appId == item.appId && xItem.contentVersionId == item.contentVersionId)
      if (fIdx != -1) {
        cloneCV[fIdx].data[iIdx].versionNumber = value
      }
      setContentVersions(cloneCV)
    }
  }

  return {
      loading: loading || processing,
      contentVersions,
      columns,
      // handleSelectGameMode,
      addNewContentVersions,
      // onChangeName,
      // onChangeNumberPlayer,
      // onChangeHasHighscore,
      onSaveContent,
      onDeleteContent,
      onChangeDes,
      onChangeVersion,
  };
};

export default useGameContentVersionsHook;
