
import { useHistory, useParams } from "react-router-dom";
import { 
  useAdminAddGameItemVersionDataMutation, 
  useGetAllGameTypesDataQuery, 
  useGetAllGameOptionsDataQuery, 
  useGetAllServerAppContentVersionQuery,
  useGetAGameItemVersionDataQuery,
  useAdminGetGameCategoriesVersionDataMutation,
  useGetAppsQuery,
} from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { formSchema } from "./formSchemal";
import { CONSTANTS } from "consts";
import { errorHandle } from "utils";

const context = {}

type gameInfoType = {
  id: string
  contentVersionId: number
  gameName: string
  shortDescription: string
  longDescription: string
  displayTime: string
  fileConfig: string
  customConfig: object
  gameOption: string
  thumbnail: string
  difficulty: object
  tags: any
  gameDuration: number
  allowUser: string[]
  restrictMachineType: string
  status: string
  gameTypeId: number
  categoryId: string
}
type gameTypesType = {
  id: number
  name: string
}

type serverAppContentVersionType = {
  serverVersionId: number,
  appId: number,
  contentVersionIds: number[],
  versionNumbers: string[],
  appName: string,
  description: string,
  appGroupId: number,
}

const useGameInfoHook = () => {
  const params = useParams();
  const history = useHistory();
  const [addGameItemVersion] = useAdminAddGameItemVersionDataMutation()
  const [getGameCategoriesVersions] = useAdminGetGameCategoriesVersionDataMutation()

  const formik = useFormik({
    initialValues: {
      id: 'Game_',
      contentVersionId: 1,
      oldContentVersionId: 1,
      gameName: 'Game',
      shortDescription: '',
      longDescription: '',
      displayTime: '',
      fileConfig: '',
      customConfig: {},
      gameOption: '',
      thumbnail: '',
      difficulty: {},
      tags: [],
      gameDuration: 0,
      allowUser: [],
      restrictMachineType: '',
      status: '',
      gameTypeId: '',
      categoryId: '',
    },
    validationSchema: formSchema,
    onSubmit: (values) => {},
  });

  const { data: dataApp } = useGetAppsQuery({
    context
  });
  const [availableApps, setAvailableApps] = React.useState([] as any[]);

  const {
    data: gameInfo,
    loading: gameInfoLoading,
  } = useGetAGameItemVersionDataQuery({
    variables: {
      input: {
        //@ts-ignore
        id: params?.id,
        //@ts-ignore
        versionId: Number(params?.versionId),
      },
    },
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    if (gameInfo && gameInfo?.getAGameItemVersionData) {
      const detail = gameInfo?.getAGameItemVersionData;
      formik.setValues({
        id: detail?.id,
        contentVersionId: detail?.contentVersionId,
        oldContentVersionId: detail?.contentVersionId,
        gameName: detail?.gameName,
        shortDescription: detail?.shortDescription,
        longDescription: detail?.longDescription,
        displayTime: detail?.displayTime,
        fileConfig: detail?.fileConfig,
        customConfig: detail?.customConfig,
        gameOption: detail?.gameOption,
        thumbnail: detail?.thumbnail,
        difficulty: detail?.difficulty,
        tags: detail?.tags,
        gameDuration: +detail?.gameDuration,
        allowUser: detail?.allowUser.split(',') || [],
        restrictMachineType: detail?.restrictMachineType,
        status: detail?.status,
        gameTypeId: detail?.gameTypeId,
        categoryId: detail?.categoryId,
      });
      // set inital value for condition form
    }
  }, [gameInfo]);

  const [loading, setLoading] = React.useState(false);
  const { data: dataGT } = useGetAllGameTypesDataQuery({
    context,
    fetchPolicy: 'no-cache',
  });
  const [gameTypes, setGameTypes] = React.useState([] as gameTypesType[]);

  const [availableCategoriesVersions, setAvailableCategoriesVersions] = React.useState([] as any[]);

  const [allContentVersions, setAllContentVersions] = React.useState([] as any[]);
  const [availableContentVersions, setAvailableContentVersions] = React.useState([] as any[]);
  const { data: serverAppContentVersion } = useGetAllServerAppContentVersionQuery({context, fetchPolicy: 'no-cache',})

  React.useMemo(() => {
    if (dataGT && dataGT.getAllGameTypesData?.data?.length ) {
      setGameTypes(dataGT.getAllGameTypesData?.data.map((e: any) => {
        return {
          id: e.id,
          name: e.name,
        }
      }))
    }
  }, [dataGT]);

  const { data: dataGO } = useGetAllGameOptionsDataQuery({
    context
  });
  const [gameOptions, setGameOptions] = React.useState([] as gameTypesType[]);

  React.useMemo(() => {
    if (dataGO && dataGO.getAllGameOptionsData?.data?.length ) {
      setGameOptions(dataGO.getAllGameOptionsData?.data.map((e: any) => {
        return {
          id: e.id,
          name: e.id,
        }
      }))
    }
  }, [dataGO]);

  React.useMemo(() => {
    if (serverAppContentVersion?.getAllServerAppContentVersion?.data?.length && gameInfo?.getAGameItemVersionData && dataApp?.adminGameApps?.length) {
      const data = serverAppContentVersion?.getAllServerAppContentVersion?.data
      setAllContentVersions(data)
      const sacv = data?.find((e: any) => e.contentVersionId == gameInfo?.getAGameItemVersionData?.contentVersionId)
      const listSacv = data.filter((e: any) => e.appId ==  sacv.appId).map((e: any) => {
        return {
          id: e.contentVersionId,
          name: e.description,
          appId: e.appId,
        }
      })
      setAvailableContentVersions(listSacv)

      const availableApps = data.filter((sac: any) => sac.contentVersionId == gameInfo?.getAGameItemVersionData?.contentVersionId)
      setAvailableApps(availableApps)

      getGameCategoriesVersions({
        variables: {
          input: {
            versionIds: listSacv.map((e: any) => e.id)
          }
        }
      })
      .then(({data}) => {
        const listSacv = data?.adminGetGameCategoriesVersionData?.data.map((e: any) => {
          return {
            id: e.id,
            name: e.name,
          }
        })
        setAvailableCategoriesVersions(listSacv)
      })
    }
  }, [serverAppContentVersion, gameInfo, dataApp]);

    const onChangeTags = (name: any, value: any) => {
        formik.setFieldValue("tags", value.map((e: any) => e.value))
    }

    const onChangeAllowUser = (name: any, value: any) => {
        formik.setFieldValue("allowUser", value.map((e: any) => e.value))
    }

    const onChangeGameType = (ev: any) => {
      const {value} = ev.target
        formik.setFieldValue("gameTypeId", +value)
    }

    const onChangeCustomConfig = (
      jsObject : any,
    ) => {
      if (jsObject) {
        formik.setFieldValue("customConfig", jsObject, true);
      }
    }

    const onChangeDifficulty = (
      jsObject : any,
    ) => {
      if (jsObject) {
        formik.setFieldValue("difficulty", jsObject, true);
      }
    }

    const onChangeGameOption = (ev: any) => {
      const {value} = ev.target
        formik.setFieldValue("gameOption", value)
    }

    const onChangeGameStatus = (ev: any) => {
      const {value} = ev.target
      formik.setFieldValue("status", value)
    }

    const onChangeContentVersion = (ev: any) => {
      const {value} = ev.target
      formik.setFieldValue("contentVersionId", +value)

      const availableApps = allContentVersions.filter((e: any) => e.contentVersionId == +value)
      setAvailableApps(availableApps)
    }

    const onChangeGameCategory = (ev: any) => {
      const {value} = ev.target
      formik.setFieldValue("categoryId", value)
    }

    const handleSubmitForms = async () => {
        setLoading(true);
        if (formik.values.gameName.trim() !== "") {
          if (
            formik.isValid
          ) {
            setLoading(true);
            addGameItemVersion({
              variables: {
                input: {
                  id: formik.values.id,
                  contentVersionId: formik.values.contentVersionId,
                  oldContentVersionId: formik.values.oldContentVersionId,
                  gameName: formik.values.gameName,
                  shortDescription: formik.values.shortDescription,
                  longDescription: formik.values.longDescription,
                  displayTime: formik.values.displayTime,
                  fileConfig: formik.values.fileConfig,
                  customConfig: formik.values.customConfig,
                  gameOption: formik.values.gameOption,
                  thumbnail: formik.values.thumbnail,
                  difficulty: formik.values.difficulty,
                  tags: formik.values.tags,
                  gameDuration: +formik.values.gameDuration,
                  allowUser: formik.values.allowUser?.join(','),
                  restrictMachineType: formik.values.restrictMachineType,
                  status: formik.values.status,
                  gameTypeId: formik.values.gameTypeId,
                  categoryId: formik.values.categoryId,
                  action: "update",
              }},
            }).then((res)=>{
              setLoading(false);
              toast.success("Update Game Item Success", { autoClose: 3000 });
              history.push(CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO)
            }).catch((err)=>{
              errorHandle(err, formik)
              setLoading(false)
            }).finally(()=>{
              setLoading(false);
            });
          } else if (!formik.isValid) {
            setLoading(false);
            const el = document.getElementById("profileFormId");
            el?.scrollIntoView();
            return;
          } else {
            setLoading(false);
            alert("have error");
          }
        } else {
          setLoading(false);
        }
      }

    return {
        loading: loading || gameInfoLoading,
        formik,
        gameTypes,
        gameOptions,
        availableApps,
        availableContentVersions,
        availableCategoriesVersions,
        onChangeTags,
        onChangeAllowUser,
        handleSubmitForms,
        onChangeGameType,
        onChangeCustomConfig,
        onChangeDifficulty,
        onChangeGameOption,
        onChangeGameStatus,
        onChangeContentVersion,
        onChangeGameCategory,
    };
};

export default useGameInfoHook;
