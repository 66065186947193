
import { useGetAllGameOptionsDataQuery, useAdminAddGameOptionsDataMutation } from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const context = {}

type gameOptionsType = {
    id: string
    option1: string
    option2?: string
    edited: boolean
    idx: number
}

const useGameOptionsHook = () => {
    const [addGameOptionsData] = useAdminAddGameOptionsDataMutation()
    const { data, loading } = useGetAllGameOptionsDataQuery({
      context
    });
    const [gameOptions, setGameOptions] = React.useState([] as gameOptionsType[]);
    React.useMemo(() => {
      if (data && data.getAllGameOptionsData?.data?.length) {
        setGameOptions(data.getAllGameOptionsData?.data?.sort((e1: any, e2: any) => {
          const m1 = e1.id.match(/\d+/)
          let s1 = 0
          if (m1) {
            s1 = +m1[0]
          }
          const m2 = e2.id.match(/\d+/)
          let s2 = 0
          if (m2) {
            s2 = +m2[0]
          }
          return s2 - s1
        }).map((e: any, idx: number) => {
            return {
              id: e.id,
              option1: e.option1,
              option2: e.option2,
              edited: false,
              idx,
            }
        }))
      }
    }, [data]);

    const columns = [
      {
        numeric: false,
        disablePadding: false,
        label: "ID",
        id: "id",
        underline: true,
        width: "15%",
        hasSub: true,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Option1",
        id: "option1",
        width: "40%",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Option2",
        id: "option2",
        width: "40%",
      }
    ]

    const onChangeGameOptionsName = (event: any, gameOptions: any) => {
        const {value} = event.target
        setGameOptions(gameOptions.map((item: any) => {
            if (item.id === gameOptions.id) {
                item.name = value
            } 
            return item;
        })
      )
    }
    
    const onAddNewOption = () => {
        const newIdx = Math.max(...gameOptions.map(e => e.idx)) + 1
        const maxId = Math.max(...gameOptions.map(e => {
          const matches = e.id.match(/\d+/)
          if (matches) {
              return +matches[0]
          }
          // const toNum = (e?.ID || '0').match(/\d+/)[0]
          return 0
      })) + 1 
        const newGopt: gameOptionsType = {
            id: 'Game_Option_' + maxId,
            option1: '{}',
            edited: false,
            idx: newIdx,
        }
        setGameOptions([newGopt, ...gameOptions])
    }
    
    const onSaveChange = async () => {
      const dataToSave = gameOptions.filter(e => e.edited).map(e => {
        return {
          id: e.id,
          option1: e.option1,
          option2: e.option2,
        }
      }).filter(e => !!e.id)
      if (!dataToSave.length) {
        toast.error("Nothing changed !!!")
        return
      }
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      });
      if (!result.isConfirmed) {
        return;
      }
      try {
        await addGameOptionsData({
          variables: {
              input: {data: dataToSave},
          },
        })
        toast.success("Update Game Options success");
      } catch (e) {
        toast.error("Update Game Options ERROR");
      }
    }
    
    const onChangeOption1 = (
        idx : any,
        jsObject: any,
      ) => {
        if (!Object.keys(jsObject).length) {
          toast.error("Option 1 cannot be empty !!!")
          return
        }
        const dataToSet = gameOptions.map((e: gameOptionsType) => {
            if (e.idx == +idx) {
                e.option1 = JSON.stringify(jsObject)
                e.edited = true
            }
            return e
        })
        setGameOptions(dataToSet)
      }
    
    const onChangeOption2 = (
        idx : any,
        jsObject: any,
      ) => {
        const dataToSet = gameOptions.map((e: gameOptionsType) => {
            if (e.idx == +idx) {
              if (!Object.keys(jsObject || {}).length) {
                delete e?.option2
              } else {
                e.option2 = JSON.stringify(jsObject)
              }
                e.edited = true
            }
            return e
        })
        setGameOptions(dataToSet)
    }
    
    const handleChangeId = (ev: any) => {
        const { name, value } = ev.target;
        const dataToSet = gameOptions.map((e: gameOptionsType) => {
            if (e.idx == +name) {
                e.id = value
                e.edited = true
            }
            return e
        })
        setGameOptions(dataToSet)
    }
    
    return {
        loading,
        gameOptions,
        columns,
        onChangeGameOptionsName,
        onAddNewOption,
        onSaveChange,
        onChangeOption1,
        onChangeOption2,
        handleChangeId,
    };
};

export default useGameOptionsHook;
