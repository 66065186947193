import React, { useEffect, useState } from "react";
import style from "./Collapse.module.scss";
import { ReactComponent as CollapseIcon } from "Components/base/Icons/ArrowDown.svg";

interface CollapseProps {
    headerText: string;
    defaultOpen?: boolean;
    children?: any;
    addIconProps?: {
        action?: Function;
        text?: any;
    } | null;
}
const Collapse = ({
    children,
    headerText,
    defaultOpen,
    addIconProps,
}: CollapseProps) => {
    // console.log({defaultOpen})
    const [open, setOpen] = useState(true);
    const handleClickCollapse = () => {
        setOpen(!open);
    };
    useEffect(()=>{
        setOpen(!!defaultOpen);
    },[defaultOpen])
    return (
        <div className={style["Collapse"] + " CollapseCard"}>
            <div className={open ? style["opened"] : style['closed']}>
                <div className={style["header"] + " header"}>
                    <div className={style["text"]}>{headerText}</div>
                    {addIconProps && (
                        <div
                            className={style["removeCollaspe"]}
                            //@ts-ignore
                            onClick={addIconProps?.action}
                        >
                          <i className="icon-e-add" />{addIconProps?.text || "Add More"}
                        </div>
                    )}
                    <div
                        className={style["iconCollapse"]}
                        onClick={handleClickCollapse}
                    >
                        <CollapseIcon />
                    </div>
                </div>
                <div
                    className={style["content"]}
                    style={{ display: open ? "block" : "none" }}
                >
                    <div
                        className={
                            style["childrenContent"] + " childrenContent"
                        }
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Collapse;
