
import { useHistory } from "react-router-dom";
import { 
  useAdminAddGameItemVersionDataMutation, 
  useGetAllGameTypesDataQuery, 
  useGetAllGameOptionsDataQuery, 
  useGetAllServerAppContentVersionQuery,
  useAdminGetGameCategoriesVersionDataMutation,
} from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { formSchema } from "./formSchemal";
import { CONSTANTS } from "consts";
import { errorHandle } from "utils";

const context = {}

type gameInfoType = {
  id: string
  contentVersionId: number
  gameName: string
  shortDescription: string
  longDescription: string
  displayTime: string
  fileConfig: string
  customConfig: object
  gameOption: string
  thumbnail: string
  difficulty: object
  tags: any
  gameDuration: number
  allowUser: string[]
  restrictMachineType: string
  status: string
  gameTypeId: number
  categoryId: string
}
type gameTypesType = {
  id: number
  name: string
}

type serverAppContentVersionType = {
  serverVersionId: number,
  appId: number,
  contentVersionIds: number[],
  versionNumbers: string[],
  appName: string,
  description: string,
  appGroupId: number,
}

const useGameInfoHook = () => {
  const history = useHistory();
  const [addGameItemVersion] = useAdminAddGameItemVersionDataMutation()
  const [getGameCategoriesVersions] = useAdminGetGameCategoriesVersionDataMutation()

  const formik = useFormik({
    initialValues: {
      id: 'Game_',
      contentVersionId: 1,
      gameName: 'Game',
      shortDescription: '',
      longDescription: '',
      displayTime: '',
      fileConfig: '',
      customConfig: {},
      gameOption: '',
      thumbnail: '',
      difficulty: {},
      tags: [],
      gameDuration: '',
      allowUser: [],
      restrictMachineType: '',
      status: '',
      gameTypeId: '',
      categoryId: '',
    },
    validationSchema: formSchema,
    onSubmit: (values) => {},
  });

  const [loading, setLoading] = React.useState(false);
  const { data: dataGT } = useGetAllGameTypesDataQuery({
    context,
    fetchPolicy: 'no-cache',
  });
  const [gameTypes, setGameTypes] = React.useState([] as gameTypesType[]);

  const [allcategoriesVersions, setAllcategoriesVersions] = React.useState([] as any[]);
  const [availableCategoriesVersions, setAvailableCategoriesVersions] = React.useState([] as any[]);

  const [allContentVersions, setAllContentVersions] = React.useState([] as any[]);
  const { data: serverAppContentVersion, loading: loadingSAC } = useGetAllServerAppContentVersionQuery({context, fetchPolicy: 'no-cache',})

  const [availableApps, setAvailableApps] = React.useState([] as any[]);

  React.useMemo(() => {
    if (dataGT && dataGT.getAllGameTypesData?.data?.length ) {
      setGameTypes(dataGT.getAllGameTypesData?.data.map((e: any) => {
        return {
          id: e.id,
          name: e.name,
        }
      }))
    }
  }, [dataGT]);

  const { data: dataGO } = useGetAllGameOptionsDataQuery({
    context,
    fetchPolicy: 'no-cache',
  });
  const [gameOptions, setGameOptions] = React.useState([] as gameTypesType[]);

  React.useMemo(() => {
    if (dataGO && dataGO.getAllGameOptionsData?.data?.length ) {
      setGameOptions(dataGO.getAllGameOptionsData?.data.map((e: any) => {
        return {
          id: e.id,
          name: e.id,
        }
      }))
    }
  }, [dataGO]);

  React.useMemo(() => {
    if (serverAppContentVersion?.getAllServerAppContentVersion?.data?.length) {
      const data = serverAppContentVersion?.getAllServerAppContentVersion?.data
      const listSacv = data.map((e: any) => {
        return {
          id: e.contentVersionId,
          name: e.description,
          appName: e.appName,
          appId: e.appId,
        }
      })
      setAllContentVersions(listSacv)

      const defaultContentVersionId = (listSacv[0] || {}).id || 1
      formik.setFieldValue("contentVersionId", defaultContentVersionId)

      const availableApps = listSacv.filter((e: any) => e.id == defaultContentVersionId)
      setAvailableApps(availableApps)

      getGameCategoriesVersions({
        variables: {
          input: {
            versionIds: data.map((e: any) => e.contentVersionId)
          }
        },
        fetchPolicy: 'no-cache',
      })
      .then(({data}) => {
        const allCates = data?.adminGetGameCategoriesVersionData?.data.map((e: any) => {
          return {
            id: e.id,
            name: e.name,
            contentVersionId: e.contentVersionId,
          }
        })
        setAllcategoriesVersions(allCates)

        const availCates = allCates.filter((e: any) => e.contentVersionId == defaultContentVersionId)
        setAvailableCategoriesVersions(availCates)
      })
    }
  }, [serverAppContentVersion]);

    const onChangeTags = (name: any, value: any) => {
        formik.setFieldValue("tags", value.map((e: any) => e.value))
    }

    const onChangeAllowUser = (name: any, value: any) => {
        formik.setFieldValue("allowUser", value.map((e: any) => e.value))
    }

    const onChangeGameType = (ev: any) => {
      const {value} = ev.target
        formik.setFieldValue("gameTypeId", +value)
    }

    const onChangeCustomConfig = (
      jsObject : any,
    ) => {
      if (jsObject) {
        formik.setFieldValue("customConfig", jsObject, true);
      }
    }

    const onChangeDifficulty = (
      jsObject : any,
    ) => {
      if (jsObject) {
        formik.setFieldValue("difficulty", jsObject, true);
      }
    }

    const onChangeGameOption = (ev: any) => {
      const {value} = ev.target
        formik.setFieldValue("gameOption", value)
    }

    const onChangeGameStatus = (ev: any) => {
      const {value} = ev.target
      formik.setFieldValue("status", value)
    }

    const onChangeContentVersion = (ev: any) => {
      const {value} = ev.target
      formik.setFieldValue("contentVersionId", +value)

      const availableApps = allContentVersions.filter((e: any) => e.id == +value)
      setAvailableApps(availableApps)
    }

    const onChangeGameCategory = (ev: any) => {
      const {value} = ev.target
      formik.setFieldValue("categoryId", value)
    }

    const handleSubmitForms = async () => {
        setLoading(true);
        if (formik.values.gameName.trim() !== "") {
          if (
            formik.isValid
          ) {
            setLoading(true);
            addGameItemVersion({
              variables: {
                input: {
                  id: formik.values.id,
                  contentVersionId: formik.values.contentVersionId,
                  gameName: formik.values.gameName,
                  shortDescription: formik.values.shortDescription,
                  longDescription: formik.values.longDescription,
                  displayTime: formik.values.displayTime,
                  fileConfig: formik.values.fileConfig,
                  customConfig: formik.values.customConfig,
                  gameOption: formik.values.gameOption,
                  thumbnail: formik.values.thumbnail,
                  difficulty: formik.values.difficulty,
                  tags: formik.values.tags,
                  gameDuration: +formik.values.gameDuration,
                  allowUser: formik.values.allowUser?.join(','),
                  restrictMachineType: formik.values.restrictMachineType,
                  status: formik.values.status,
                  gameTypeId: formik.values.gameTypeId,
                  categoryId: formik.values.categoryId,
                  action: "create",
              }},
            }).then((res)=>{
              setLoading(false);
              toast.success("Add Game Item Success", { autoClose: 3000 });
              history.push(CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO)
            }).catch((err)=>{
              errorHandle(err, formik)
              setLoading(false)
            }).finally(()=>{
              setLoading(false);
            });
          } else if (!formik.isValid) {
            setLoading(false);
            const el = document.getElementById("profileFormId");
            el?.scrollIntoView();
            return;
          } else {
            setLoading(false);
            alert("have error");
          }
        } else {
          setLoading(false);
        }
      }

    return {
        loading: loading || loadingSAC,
        formik,
        gameTypes,
        gameOptions,
        availableApps,
        allContentVersions,
        availableCategoriesVersions,
        onChangeTags,
        onChangeAllowUser,
        handleSubmitForms,
        onChangeGameType,
        onChangeCustomConfig,
        onChangeDifficulty,
        onChangeGameOption,
        onChangeGameStatus,
        onChangeContentVersion,
        onChangeGameCategory,
    };
};

export default useGameInfoHook;
