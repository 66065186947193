
import { useHistory } from "react-router-dom";
import { 
    useAdminAddGameCategoriesVersionDataMutation, 
    useAdminDeleteGameCategoriesVersionDataMutation ,
    useGetAllServerAppContentVersionQuery,
    useAdminGetGameCategoriesVersionDataMutation,
    useAdminGetGameItemVersionDataMutation,
    useGetAppsQuery,
} from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const context = {}

type gameCategoriesType = {
  id: string
  name: string
  contentVersionId: number
  description: string
  container: string
  thumbnail: string
  sortOrder: number
  open: boolean
  textEdit: boolean
  edited: boolean
}

type gameInfoType = {
    ID: string
    Name: string
}

const useGameCategoriesHook = () => {
    const history = useHistory();
    const [addGameCategoriesVersion] = useAdminAddGameCategoriesVersionDataMutation()
    const [deleteGameCategoriesVersion] = useAdminDeleteGameCategoriesVersionDataMutation()
    const [getGameCategoriesVersions] = useAdminGetGameCategoriesVersionDataMutation()
    const [getGameItemVersionData] = useAdminGetGameItemVersionDataMutation()

    const [loadingCates, setLoadingCates] = React.useState(false);
    const [loadingGameItems, setLoadingGameItems] = React.useState(false);

    const [gameInfo, setGameInfo] = React.useState([] as gameInfoType[]);
    const [apps, setApps] = React.useState([] as any[]);
    const [availableApps, setAvailableApps] = React.useState([] as any[]);
    const [currentApp, setCurrentApp] = React.useState(1);

    const [allcategoriesVersions, setAllcategoriesVersions] = React.useState([] as any[]);
    const [availableCategoriesVersions, setAvailableCategoriesVersions] = React.useState([] as any[]);
    const [allContentVersions, setAllContentVersions] = React.useState([] as any[]);

    const [availableContentVersions, setAvailableContentVersions] = React.useState([] as any[]);
    const { data: serverAppContentVersion, loading } = useGetAllServerAppContentVersionQuery({context, fetchPolicy: 'no-cache',})

    React.useMemo(() => {
      if (serverAppContentVersion?.getAllServerAppContentVersion?.data?.length) {
        const dataSACV = serverAppContentVersion?.getAllServerAppContentVersion?.data
        const allApps = {} as any
        const listSacv = dataSACV.map((e: any) => {
          allApps[e.appId] = {
            Name: e.appName,
            ID: e.appId,
          }
          return {
            id: e.contentVersionId,
            name: e.description,
            appName: e.appName,
            appId: e.appId,
          }
        })
        setApps(Object.values(allApps))
        setAllContentVersions(listSacv)

        const availCVs = listSacv.filter((sac: any) => sac.appId == currentApp)
        setAvailableContentVersions(availCVs)
        const allVIds = availCVs.map((ee: any) => ee.id)

        setLoadingCates(true)
        getGameCategoriesVersions({
          variables: {
            input: {
              versionIds: allVIds
            }
          },
          fetchPolicy: 'no-cache',
        })
        .then(({data}) => {
          const allCates = data?.adminGetGameCategoriesVersionData?.data.map((e: any) => {
            return {
              id: e.id,
              name: e.name,
              oldContentVersionId: e.contentVersionId,
              contentVersionId: e.contentVersionId,
              description: e.description,
              container: e.container,
              thumbnail: e.thumbnail,
              sortOrder: e.sortOrder,
              open: false,
              textEdit: false,
              edited: false,
            }
          })
          setAllcategoriesVersions(allCates)
  
          const availCates = allCates.filter((e: any) => allVIds.includes(e.contentVersionId))
          setAvailableCategoriesVersions(availCates)
        })
        .finally(() => {
          setLoadingCates(false)
        })

        setLoadingGameItems(true)
        getGameItemVersionData({
          variables: {
            input: {
              versionIds: allVIds
            }
          },
          fetchPolicy: 'no-cache',
        })
        .then(({data}) => {
          if (data?.adminGetGameItemVersionData?.data) {
            setGameInfo(data?.adminGetGameItemVersionData?.data.map((e: any) => {
              return {
                  ID: e.id,
                  Name: e.gameName,
              }
            }))
          }
        })
        .finally(() => {
          setLoadingGameItems(false)
        })
      }
    }, [serverAppContentVersion]);

    const columns = [
        {
            disablePadding: false,
            label: "ID",
            id: "ID",
            width: "50px"
        },
        {
            label: "Version Id",
            id: "contentVersionId",
            width: "50px"
        },
        {
            label: "Name",
            id: "Name",
            width: "120px"
        },
        {
            label: "Description",
            id: "Description",
            width: "200px"
        },
        {
            label: "Container",
            id: "Container",
        },
        {
            label: "Thumbnail",
            id: "Thumbnail",
            width: "120px"
        },
        {
            label: "Sort",
            id: "SortOrder",
            width: "40px"
        },
      ]

    const handleSelectGame = (name: any, value: any) => {
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
          if (e.id == name) {
              e.container = value.map((el: any) => el.value).join(',')
              e.edited = true
          }
          return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }

    const onSwitch = (ev: any, checked: boolean) => {
      const { name } = ev.target;
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
        if (e.id == name) {
          e.textEdit = checked
          e.edited = true
        }
        return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }

    const onEditContainerText = (item: any, newValue: any) => {
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
        if (e.id == item.id && e.contentVersionId == item.contentVersionId) {
          e.container = newValue
          e.edited = true
        }
        return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }

    const onChangeName = (item: any, newValue: any) => {
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
        if (e.id == item.id && e.contentVersionId == item.contentVersionId) {
          e.name = newValue
          e.edited = true
        }
        return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }

    const onChangeDesc = (item: any, newValue: any) => {
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
        if (e.id == item.id && e.contentVersionId == item.contentVersionId) {
          e.description = newValue
          e.edited = true
        }
        return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }

    const onChangeThumbnail = (item: any, newValue: any) => {
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
        if (e.id == item.id && e.contentVersionId == item.contentVersionId) {
          e.thumbnail = newValue
          e.edited = true
        }
        return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }

    const onChangeSortOrder = (item: any, newValue: any) => {
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
        if (e.id == item.id && e.contentVersionId == item.contentVersionId) {
          e.sortOrder = parseInt(newValue)
          e.edited = true
        }
        return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }

    const onChangeVersionId = (item: any, newValue: any) => {
      const clonedCates = [...availableCategoriesVersions]
      const fIdx = clonedCates.findIndex((e: gameCategoriesType) => e.id == item.id && e.contentVersionId == item.contentVersionId)
      if (fIdx != -1) {
        clonedCates[fIdx].contentVersionId = parseInt(newValue)
        clonedCates[fIdx].edited = true
      }
      setAvailableCategoriesVersions(clonedCates)
    }
    
    const onAddNewCategory = () => {
      const newSO = Math.max(...availableCategoriesVersions.map(e => e.sortOrder)) + 1
      const maxId = Math.max(...availableCategoriesVersions.map(e => {
        const matches = e.id.match(/\d+/)
        if (matches) {
            return +matches[0]
        }
        // const toNum = (e?.ID || '0').match(/\d+/)[0]
        return 0
      })) + 1 
      const newCate: gameCategoriesType = {
        id: 'Cate_' + maxId,
        contentVersionId: availableContentVersions[0].id,
        name: '',
        description: '',
        container: '',
        thumbnail: '',
        sortOrder: newSO,
        open: false,
        textEdit: false,
        edited: false,
      }
      setAvailableCategoriesVersions([newCate, ...availableCategoriesVersions])
    }

    const onSaveChange = async () => {
      const dataToSave = availableCategoriesVersions.filter(e => e.edited).map(e => {
        return {
          id: e.id,
          contentVersionId: e.contentVersionId,
          oldContentVersionId: e.oldContentVersionId,
          name: e.name,
          description: e.description,
          container: e.container,
          thumbnail: e.thumbnail,
          sortOrder: e.sortOrder,
        }
      })
      if (dataToSave.length) {
        const {data, errors} =  await addGameCategoriesVersion({
            variables: {
              input: {data: dataToSave},
            },
          })
        if (errors && errors?.length) {
          toast.error("ERROR !!!");
        } else {
          toast.success("Success !!!");
        }
      }
    }

    const onDeleteGameCategories = async (item: any) => {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      });
      if (!result.isConfirmed) {
        return;
      }
      try {
        const {data, errors} =   await deleteGameCategoriesVersion({
          variables: {
            input: {
              "contentVersionId": item.contentVersionId,
              "id": item.id
            },
          },
        })
        const dataToSet = availableCategoriesVersions.filter((e: gameCategoriesType) => e.id != item.id)
        setAvailableCategoriesVersions(dataToSet)
        toast.success("Deleted !!!");
      } catch (e) {
          toast.error("ERROR !!!");
      }
    }

    const handleClose = (id: string, open: boolean) => {
      const dataToSet = availableCategoriesVersions.map((e: gameCategoriesType) => {
        if (e.id == id) {
          e.open = open
        }
        return e
      })
      setAvailableCategoriesVersions(dataToSet)
    }
    
    const onChangeApp = (event: any) => {
      const {value} = event.target
      setCurrentApp(+value)

      const availCVs = allContentVersions.filter((sac: any) => sac.appId == +value)
        setAvailableContentVersions(availCVs)
        const allVIds = availCVs.map((ee: any) => ee.id)

        setLoadingCates(true)
        getGameCategoriesVersions({
          variables: {
            input: {
              versionIds: allVIds
            }
          },
          fetchPolicy: 'no-cache',
        })
        .then(({data}) => {
          const allCates = data?.adminGetGameCategoriesVersionData?.data.map((e: any) => {
            return {
              id: e.id,
              name: e.name,
              contentVersionId: e.contentVersionId,
              oldContentVersionId: e.contentVersionId,
              description: e.description,
              container: e.container,
              thumbnail: e.thumbnail,
              sortOrder: e.sortOrder,
              open: false,
              textEdit: false,
              edited: false,
            }
          })
          setAllcategoriesVersions(allCates)
  
          const availCates = allCates.filter((e: any) => allVIds.includes(e.contentVersionId))
          setAvailableCategoriesVersions(availCates)
        })
        .finally(() => {
          setLoadingCates(false)
        })

        setLoadingGameItems(true)
        getGameItemVersionData({
          variables: {
            input: {
              versionIds: allVIds
            }
          },
          fetchPolicy: 'no-cache',
        })
        .then(({data}) => {
          if (data?.adminGetGameItemVersionData?.data) {
            setGameInfo(data?.adminGetGameItemVersionData?.data.map((e: any) => {
              return {
                  ID: e.id,
                  Name: e.gameName,
              }
            }))
          }
        })
        .finally(() => {
          setLoadingGameItems(false)
        })
    }

    return {
        loading: loading || loadingCates || loadingGameItems,
        columns,
        apps,
        currentApp,
        availableApps,
        // contentVersion,
        availableContentVersions,
        allContentVersions,
        availableCategoriesVersions,
        gameInfo,
        onChangeApp,
        handleSelectGame,
        onSwitch,
        onEditContainerText,
        onChangeName,
        onChangeDesc,
        onSaveChange,
        onChangeThumbnail,
        onChangeSortOrder,
        onAddNewCategory,
        onDeleteGameCategories,
        handleClose,
        // onChangeContentVersion,
        onChangeVersionId,
    };
};

export default useGameCategoriesHook;
