import {
    Grid,
    Menu,
    MenuItem,
    Button,
    SelectAutoComplete,
    DialogContent,
    DialogContentText,
    TextField,
    InputLabel,
} from "Components/base";
import Collapse from "../../../../Components/GameInfoV2/Collapse";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { ReactComponent as SaveIconFilled } from "Components/base/Icons/SaveIconFilled.svg";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameContentVersionsHook from "./GameContentVersions.hook";
import style from "./GameContentVersions.module.scss";
import { torem } from "styles/theme/toRem";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";

export const GameContentVersions = () => {
  const {
    loading,
    contentVersions,
    columns,
    // handleSelectGameMode,
    addNewContentVersions,
    // onChangeName,
    // onChangeNumberPlayer,
    // onChangeHasHighscore,
    onSaveContent,
    onDeleteContent,
    onChangeDes,
    onChangeVersion,
  } = useGameContentVersionsHook();
  const history = useHistory()

  const onClickBack = () => {
    const path = CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO;
    history.push({ pathname: path });
  }

  return loading ? (<RingLoading />) : (
    <div className={style["all-content-version"]}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div className="aviron-sub-title pointer" onClick={() => onClickBack()}>
          Game Item Management &gt; Game Content Version
          </div>
            <div className="aviron-title">Game Content Version Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup">
          {/* <Button
              variant="contained"
              onClick={onSaveChange}
              color="secondary"
              className="btn-secondary"
              data-cy="add-machine-btn"
          >
              <i className="icon-secondary" /> SAVE
          </Button> */}
        </Grid>
      </Grid>
      <div className={style["content-version-list"]}>
      {
        contentVersions.map(el => {
          return (
            <div className={style["version-item-row"]}>
              <Collapse 
                headerText={`${el.appId} - ${el.appName}`} 
                defaultOpen={true}
                addIconProps={
                    {
                      action: () =>
                        addNewContentVersions(el),
                      text: "Add Content Verion",
                    }
                }
              >
                {el.data.map((cti: any) => {
                  return (
                    <Grid
                      container
                      spacing={torem(35)}
                      className={style["version-item"]}
                      key={'content-version-' + cti.appId + '-' + cti.contentVersionId}
                    >
                      <Grid item xs={2}>
                        <InputLabel>Content Version Id</InputLabel>
                        <TextField disabled value={cti.edited ? 'auto generated' : cti.contentVersionId} />
                      </Grid>
                      <Grid item xs={4}>
                        <InputLabel>Description</InputLabel>
                        <TextField name="Description" disabled={!cti.edited} fullWidth value={cti.description} onChange={(ev: any) => onChangeDes(ev, cti)} />
                      </Grid>
                      <Grid item xs={4}>
                        <InputLabel>Version</InputLabel>
                        <TextField name="Version" disabled={!cti.edited} value={cti.versionNumber} onChange={(ev: any) => onChangeVersion(ev, cti)} />
                      </Grid>
                      <Grid item xs={1}>
                        <div onClick={() => onDeleteContent(cti)} className={style["remove-content"]}>
                          <TrashIcon />
                        </div>
                        {cti.edited
                        ? 
                        (
                        <div onClick={() => onSaveContent(cti)} className={style["add-content"]}>
                          <SaveIconFilled />
                        </div>
                        )
                        : null}
                      </Grid>
                    </Grid>
                  )
                })}
              </Collapse>
            </div>
          )
        })
      }
      </div>
    </div>
  );
};
