import {
    Grid,
    Menu,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Select,
    Button,
    SelectAutoComplete,
    DialogContent,
    DialogContentText,
    Typography,
    FormControl,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameCategoriesHook from "./GameCategories.hook";
import { Switch, TextField } from '@mui/material';
import DragableModal from 'Components/DragableModal'
import AppLabel from 'Components/GameInfoV2/AppLabel';
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";

interface itemProps {
    value: any;
    label: any;
    key: any;
}

export const GameCategories = () => {
    const {
        loading,
        // gameCategories,
        gameInfo,
        columns,
        apps,
        currentApp,
        availableApps,
        // contentVersion,
        availableContentVersions,
        allContentVersions,
        availableCategoriesVersions,
        onChangeApp,
        handleSelectGame,
        onSwitch,
        onEditContainerText,
        onChangeName,
        onChangeDesc,
        onSaveChange,
        onChangeThumbnail,
        onChangeSortOrder,
        onAddNewCategory,
        onDeleteGameCategories,
        handleClose,
        // onChangeContentVersion,
        onChangeVersionId,
    } = useGameCategoriesHook();

    const [openModel, setOpenModel] = React.useState(false)
    const [modalData, setModalData] = React.useState<itemProps[]>([])
    const [currentEdit, setCurrentEdit] = React.useState('')


    const mapContainerToSelected = (container: string) => {
      return container.split(',').map(g => {
        const item = gameInfo.find(gi => gi.ID == g)
        if (!item) {
            return false
        }
        return {
            value: item.ID,
            label: item.ID,
            key: item.ID
        }
      }).filter(Boolean)
    }

    const onClickOpenModal = (id:any, container: any) => {
      setCurrentEdit(id)
      if (!modalData || !modalData.length) {
          const con = container.split(',')
          const dataFiltered = gameInfo.filter((gi: any) => con.find((el: any) => gi.ID == el))
          setModalData(dataFiltered.map((item: any)=>{
            return {
                value: item.ID, 
                label: `${item.ID} - ${item.Name}`,
                key: item.ID
            }
          }))
      }
      setOpenModel(true)
    }

    const onChangeModalData = (newData: any) => {
      handleSelectGame(currentEdit, newData)
      setModalData(newData)
      setCurrentEdit('')
    }
    const history = useHistory()
  
    const onClickBack = () => {
      const path = CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO;
      history.push({ pathname: path });
    }

    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    return loading ? (<RingLoading />) : (
        <div style = {{paddingLeft: 50, paddingRight: 50, paddingBottom: 100}}>
          <Grid
              container
              spacing={2}
              className="headerToolbarGrid"
              alignItems={"center"}
          >
              <Grid item xs={12} md={6} lg={3}>
              <div className="aviron-sub-title pointer" onClick={() => onClickBack()}>
                Game Item Management &gt; Game Categories
                </div>
                <div className="aviron-title">Game Categories Management</div>
              </Grid>
              <Grid item xs={12} md={6} lg={9} className="buttonGroup"> 
                <div style={{marginRight: '15px'}}>
                  <Select
                    value={currentApp}
                    // options={gameTypesOptions}
                    onChange={onChangeApp}
                    name="app_selection"
                    
                    fullWidth
                  >
                      {apps?.map(app => {
                          return (
                              <MenuItem value={app.ID} key={`game-app-option-${app.ID}`}>{app.Name.toUpperCase()}</MenuItem>
                          )
                      })}
                  </Select>
                </div>
                <Button
                  variant="contained"
                  onClick={onAddNewCategory}
                  color="secondary"
                  className="btn-primary"
                  data-cy="add-machine-btn"
                >
                  <i className="icon-e-add" /> ADD GAME Category
                </Button>
                <Button
                  variant="contained"
                  onClick={onSaveChange}
                  color="secondary"
                  className="btn-secondary"
                  data-cy="add-machine-btn"
                >
                  <i className="icon-secondary" /> SAVE
                </Button>
              </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems={"left"}
            marginTop={2}
          >
            <Grid item xs={4} md={6} lg={3}>
            Available Contentversion For this app:
            </Grid>
            <Grid item xs={12} md={6} lg={6} display={'flex'} alignItems={'center'}>
              {availableContentVersions.map((app: any) => {
              return (
                <AppLabel key={`app-allow-${app.appId}-${app.id}`} text={`${app.id} - ${app.name}`} color="0, 188, 60" colorBg="rgb(230, 248, 236)" />
              )
            })}
              </Grid>
          </Grid>
          <div className="table">
            <Table className="video-list-table custom-table-element" >
              <TableHead>
                  <TableRow>
                      {columns.map((column: any) => (
                          <TableCell
                              key={`columns-${column.id}`}
                              width={column.width}
                          >
                                      {column.label}
                          </TableCell>
                      ))}
                      <TableCell key="action">
                          <span className="column-no">Actions</span>
                      </TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
              {
                  (availableCategoriesVersions || []).sort((e1: any, e2: any) => e2.sortOrder - e1.sortOrder).map((el, idx) => {
                      return (
                        <TableRow role="checkbox" className="pointer cms-table-row" key={`TableRow-${el.contentVersionId}-${el.id}-${idx}`}>
                          <TableCell>
                              {el.id}
                          </TableCell>
                          <TableCell>
                          <Select
                            value={el.contentVersionId}
                            // options={gameTypesOptions}
                            onChange={(ev: any) => onChangeVersionId(el, ev.target.value)}
                            name="app_selection"
                            
                            fullWidth
                          >
                              {availableContentVersions?.map(app => {
                                  return (
                                      <MenuItem value={app.id} key={`select-content-version-${app.id}`}>{app.id}</MenuItem>
                                  )
                              })}
                          </Select>
                          </TableCell>
                          <TableCell>
                            <TextField 
                                fullWidth 
                                name={el.id}
                                defaultValue={el.name} 
                                onBlur={(ev: any) => onChangeName(el, ev.target.value)}
                                inputProps={{ name: el.id }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField 
                                fullWidth 
                                multiline
                                name={el.id}
                                defaultValue={el.description} 
                                onBlur={(ev: any) => onChangeDesc(el, ev.target.value)}
                                inputProps={{ name: el.id }}
                            />
                          </TableCell>
                          <TableCell>
                              <Typography component={'span'}>
                                  Multi Select
                                  <Switch
                                      name={ el.id}
                                      value={el.textEdit}
                                      onChange={onSwitch}
                                      inputProps={{ name: el.id }}
                                  />
                                  Text Edit
                              </Typography>
                              <DialogContent>
                                  <DialogContentText id="alert-dialog-description">
                                    { el.textEdit ? (
                                      <FormControl fullWidth className="form-aviron">
                                        <TextField 
                                          fullWidth 
                                          multiline 
                                          name={el.id}
                                          defaultValue={el.container} 
                                          onBlur={(ev: any) => onEditContainerText(el, ev.target.value)}
                                          inputProps={{ name: el.id }}
                                        />
                                      </FormControl>
                                    ) : (
                                    <SelectAutoComplete
                                      fullWidth
                                      name={'selector-container-' + el.ID}
                                      label="Select Game"
                                      placeholder="Select Game"
                                      multiple
                                      options={gameInfo.map((item)=>{
                                        return {
                                          value: item.ID, // Game_1
                                          label: `${item.ID} - ${item.Name}`, // Game so 1
                                          key: item.ID
                                        }
                                      })}
                                      onChange={handleSelectGame}
                                      inputProps={{ name: el.id }}
                                      value={mapContainerToSelected(el.container)}
                                      isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                    />)}
                                  </DialogContentText>
                              </DialogContent>
                              <div>
                              <button
                                  aria-controls={`action-menu-${el.id}`}
                                  aria-haspopup="true"
                                  aria-describedby={(el?.id || '').toString()}
                                  className="actions-table pointer"
                                  // variant="contained"
                                  role="presentation"
                                  onClick={() => {
                                    onClickOpenModal(el.id, el.container)
                                  }}
                                  data-cy="cms-table-action-btn"
                              >
                                  Change Order
                              </button>
                              </div>
                          </TableCell>
                          <TableCell>
                              <TextField 
                                  fullWidth 
                                  name={el.id}
                                  defaultValue={el.thumbnail} 
                                  onBlur={(ev: any) => onChangeThumbnail(el, ev.target.value)}
                                  inputProps={{ name: el.id }}
                              />
                          </TableCell>
                          <TableCell>
                              <TextField 
                                  fullWidth 
                                  name={el.id}
                                  defaultValue={el.sortOrder} 
                                  onBlur={(ev: any) => onChangeSortOrder(el, ev.target.value)}
                                  inputProps={{ name: el.id }}
                              />
                          </TableCell>
                          <TableCell>
                              <div className="position-relative">
                                  <button
                                      aria-controls={`action-menu-${el.id}`}
                                      aria-haspopup="true"
                                      aria-describedby={(el?.id || '').toString()}
                                      className="actions-table pointer"
                                      // variant="contained"
                                      role="presentation"
                                      onClick={(event) => {
                                          setAnchorEl(
                                              event.currentTarget
                                          );
                                          handleClose(
                                              el.id,
                                              true
                                          );
                                      }}
                                      data-cy="cms-table-action-btn"
                                  >
                                      <i className="icon-ic_more_vert" />
                                  </button>
                                  <Menu
                                      id={`action-menu-${el.id}`}
                                      anchorEl={anchorEl}
                                      keepMounted
                                      //open={Boolean(anchorEl)}
                                      open={el.open}
                                      onClose={(event) => {
                                          setAnchorEl(null);
                                          handleClose(el.id, false);
                                      }}
                                      data-cy="cms-table-action-menu"
                                  >
                                      <MenuItem
                                        className="actions-table-delete pointer"
                                        role="presentation"
                                        onBlur={(event) =>
                                            handleClose(
                                                el.id,
                                                false
                                            )
                                        }
                                        onClick={(event) => onDeleteGameCategories(el)}
                                      >
                                        Delete
                                      </MenuItem>
                                  </Menu>
                              </div>
                          </TableCell>
                        </TableRow>
                      )
                  })
              }
              </TableBody>
            </Table>
          </div>
        <DragableModal open={openModel} data={modalData} handleClose={() => {setOpenModel(false)}} setData={onChangeModalData} />
      </div>
    );
};
